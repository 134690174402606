<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('sidebar.irrigation.ratingFeedbackReport') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              label-for="org_id"
            >
              <template v-slot:label>
                {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.division')"
              label-for="far_division_id"
            >
              <b-form-select
                plain
                id="far_division_id"
                v-model="search.far_division_id"
                :options="divisionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.district')"
              label-for="far_district_id"
            >
              <b-form-select
                plain
                id="far_district_id"
                v-model="search.far_district_id"
                :options="districtList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.upazila')"
              label-for="far_upazilla_id"
            >
              <b-form-select
                plain
                id="far_upazilla_id"
                v-model="search.far_upazilla_id"
                :options="upazilaList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.from_date')"
              label-for="from_date"
            >
              <flat-pickr class="form-control"
                v-model="search.from_date"
                placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.to_date')"
              label-for="to_date"
            >
              <flat-pickr class="form-control"
                v-model="search.to_date"
                placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('sidebar.irrigation.ratingFeedbackReport') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
            </b-button>
            <export-excel
              class="btn btn_add_new"
              :data="dataCustomizeExcel"
              :fields="json_fields"
              :title="$t('irri_pump_main.ratingFeedbackReport')"
              :worksheet="$t('irri_pump_main.ratingFeedbackReport')"
              name="Rating-Feedback-Report.xls">
              <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row>
                    <b-col>
                        <div class="container">
                            <div class="main-title">
                                <div>
                                    <h5 class="text-center font-weight-bold text-success">{{ $t('sidebar.irrigation.ratingFeedbackReport') }}</h5>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col md="12" class="table-responsive">
                        <b-table thead-class="table_head" bordered hover :items="ratings" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                            <template v-slot:cell(index)="data">
                                {{ $n(data.index + 1) }}
                            </template>
                            <template v-slot:cell(org_id)="data">
                                {{ data.item.org_id }}
                            </template>
                            <template v-slot:cell(rated_by)="data">
                                {{ data.item.rated_by }}
                            </template>
                            <template v-slot:cell(district)="data">
                                {{ data.item.district }}
                            </template>
                            <template v-slot:cell(rating)="data">
                                {{ $n(data.item.rating) }}
                            </template>
                            <template v-slot:cell(date)="data">
                                {{ data.item.updated_at | dateFormat }}
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { ratingFeedbackReport } from '../../api/routes'
import { mapGetters } from 'vuex'
import ExportPdf from '@/Utils/export-pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  Organization: 'org_name_bn',
  'Rated By': 'farmer_name_bn',
  District: 'district_name_bn',
  Rating: 'rating',
  Feedback: 'feedback_bn',
  Date: 'created_at'
}

const excelColumnBn = {
  সংস্থা: 'org_name_bn',
  'রেটিং দিয়েছেন': 'farmer_name_bn',
  জেলা: 'district_name_bn',
  রেটিং: 'rating',
  ফিডব্যাক: 'feedback_bn',
  তারিখ: 'created_at'
}

export default {
  name: 'UiDataTable',
  data () {
    return {
      ratings: [],
      search: {
        org_id: 0,
        far_division_id: 0,
        far_district_id: 0,
        far_upazilla_id: 0,
        from_date: '',
        to_date: ''
      },
      id: 0,
      districtList: [],
      upazilaList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    currentLocale () {
      return this.$i18n.locale
    },
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomizeExcel () {
        const listData = this.ratings
        var serial = 0
        const listContractor = listData.map(item => {
          serial += 1
          return Object.assign({}, item, {
            serial: this.$n(serial),
            rating: this.$n(item.rating),
            complain_id: this.$n(item.complain_id, { useGrouping: false }),
            created_at: this.$d(new Date(item.created_at))
          })
        })
        return listContractor
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('pump_install.organization'), class: 'text-left' },
            { label: this.$t('irri_pump_main.rated_by'), class: 'text-left' },
            { label: this.$t('irri_pump_main.district'), class: 'text-left' },
            { label: this.$t('irri_pump_main.rating'), class: 'text-left' },
            { label: this.$t('irri_pump_main.feedback'), class: 'text-left' },
            { label: this.$t('irri_pump_main.date'), class: 'text-left' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
                { key: 'index' },
                { key: 'org_name_bn' },
                { key: 'farmer_name_bn' },
                { key: 'district_name_bn' },
                { key: 'rating' },
                { key: 'feedback_bn' },
                { key: 'date' }
            ]
        } else {
            keys = [
                { key: 'index' },
                { key: 'org_name' },
                { key: 'farmer_name' },
                { key: 'district_name' },
                { key: 'rating' },
                { key: 'feedback' },
                { key: 'date' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    divisionList: function () {
      const divisions = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisions.map((item) => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn !== 'undefined' ? item.text_bn : '' }
          } else {
              return { value: item.value, text: item.text_en !== 'undefined' ? item.text_en : '' }
          }
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.far_division_id) {
              this.districtList = this.getDistrictList()
            }
            if (this.search.far_district_id) {
              this.upazilaList = this.getUpazilaList()
            }
        }
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
  },
  methods: {
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          far_division_id: this.authUser.office_detail.division_id,
          far_district_id: this.authUser.office_detail.district_id,
          far_upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search)
      await RestApi.getData(irriSchemeServiceBaseUrl, ratingFeedbackReport, params).then(response => {
        if (response.success) {
          this.ratings = this.formatList(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    formatList (data) {
      try {
        const orgList = this.$store.state.orgList
        const districtList = this.$store.state.commonObj.districtList
        let tmpData = {}
        const listData = data.map(item => {
          tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
          const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
          tmpData = districtList.find(district => district.value === item.district_id)
          const districtData = { district_name: tmpData.text_en, district_name_bn: tmpData.text_bn }
          return Object.assign({}, item, orgData, districtData)
        })
        return listData
      } catch (error) {
        if (error) {
         //
        }
        return []
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('pump_install.organization'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irri_pump_main.rated_by'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irri_pump_main.district'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irri_pump_main.rating'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irri_pump_main.feedback'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irri_pump_main.date'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$t('sidebar.irrigation.ratingFeedbackReport')
      const columnWids = ['7%', '*', '*', '10%', '5%', '*', '10%']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
    },
    getPdfData () {
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no' },
          { key: 'org_name_bn' },
          { key: 'farmer_name_bn' },
          { key: 'district_name_bn' },
          { key: 'rating' },
          { key: 'feedback_bn' },
          { key: 'created_at' }
        ]
      } else {
        keys = [
          { key: 'serial_no' },
          { key: 'org_name' },
          { key: 'farmer_name' },
          { key: 'district_name' },
          { key: 'rating' },
          { key: 'feedback' },
          { key: 'created_at' }
        ]
      }
      var serial = 0
      return this.ratings.map(item => {
          serial += 1
          const rowData = keys.map(keyItem => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'rating') {
              return { text: this.$n(item.rating, { useGrouping: false }) }
            }
            if (keyItem.key === 'created_at') {
              return { text: this.$d(new Date(item.created_at)) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })
    }
  }
}
</script>
